import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"
import ImageGallery from "../components/ImageGallery"

import PosterThumbnail from "../images/reu/posterThumbnail.png"

import PosterHighRes from "../documents/REUPoster.pdf"

import PenTestImg from "../images/reu/first_test.jpg"
import ProcessImg from "../images/reu/printing_process.jpg"
import SampleImg from "../images/reu/sample_print.jpg"
import ThumbImg from "../images/project_thumbnails/reu.jpg"

const imgs = [
    {
        img: ThumbImg,
        alt: "Side View printing process",
    },
    {
        img: PenTestImg,
        alt: "Test process using a pencil as a placeholder",
    },
    {
        img: SampleImg,
        alt: "Sample output, honeycomb pattern",
    },
    {
        img: ProcessImg,
        alt: "Printing process top down view",
    },
];

const Reu = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Summer Research 2019</h1>
            <p>
                During the summer of 2019, I was a member of the National Science Foundation's (NSF) REU (Research Experiences for Undergraduates) Site on <a href="https://cybermanufacturing.tamu.edu/">Cybermanufacturing</a> (Grant <a href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=1757882&amp;HistoricalAwards=false">#1757882</a>). I worked directly in <a href="https://engineering.tamu.edu/industrial/profiles/wang-shiren.html">Dr. Shiren Wang's</a> lab at Texas A&amp;M University on the development of 3D Printing of Continuous Carbon Fiber Reinforced Thermosetting Composites. Additionally, I was part of Texas A&amp;M's College of Engineering's <a href="https://engineering.tamu.edu/admissions-and-aid/undergraduate-summer-research-grants/index.html">Undergraduate Summer Research Grant (USRG)</a> program, requiring preparation of a paper and poster at the conclusion of the 10-week summer research experience.
            </p>
            <p>
                The printing material and process was of great interest. Both thermosets and carbon fiber are known for their strength. Additionally, prior work had validated both items as creating strong 3D printed parts. Therefore, by combining the two, it is hoped that an even stronger composite could be developed. To realize the printing apparatus, a 5-axis robotic arm from Dorna Robotics was used. My first task was to design the printing mechanism. This was determined to be a syringe held on the Dorna arm, and couplers to interface the syringe with the arm.
            </p>
            <p>
                The next point of interest was the control of the arm. Due to how the composite is printed, there was concern that holding the tool perpendicular to the surface would result in too much fiber relative to the thermosetting matrix, resulting in a weak composite as it would behave too much like a fiber and have no compressive strength. This paradigm guided the software development. Not only was it required to follow a path specified in by a ".GCode" file, but the tool must also be held at a specific angle. With this in mind, the software development process began. To solve this, the inverse kinematics of the robot arm system had to be determined. This required me to study the forward kinematics and Denavit-Hartenburg convention. A python solution was developed and printing could commence.
            </p>
            <p>
                What was discovered was unsurprising. First it was shown that the angle between the tool head and the print surface influences the ratio of matrix to fiber. When the tool is held at a lower angle, there is more matrix material to fiber. This is presumably due to the fiber being pulled out of the syringe with less contact between the syringe-nozzle and the fiber. In turn, this ratio influences the strength of composite: where there is a larger fiber content, the composite is stronger. This demonstrates in-situ control of the 3D printing material and its mechanical properties.
            </p>
            <p>
                I continued to work on this research project into the fall of 2019 in conjunction with Dr. Wang and his new <a href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=1934120&amp;HistoricalAwards=false">NSF Grant (#1934120)</a>. I faded out of the project over the semester, mostly because the material science needed more refinement. The paper and poster prepared for the summer program is linked below. Some papers that build on this (from a material science perspective) are <a href="https://www.sciencedirect.com/science/article/pii/S2772369022000056">this</a> and <a href="https://aiche.onlinelibrary.wiley.com/doi/abs/10.1002/amp2.10114">this</a>. I can't speak at all to the materials science side, but I can say the computer science side (controls and planning) had very interesting problems such as planning paths where cutting of the fiber was minimized or eliminated.
            </p>
            <hr/>
           <ImageGallery value={imgs}/>
            <hr/>
            <div id="posterThumbnailDiv">
                <a href={PosterHighRes} download>
                    <img src={PosterThumbnail} alt="Research Poster"/>
                </a>
                <p>Click for High Resolution .pdf file</p>
            </div>
            <br></br>

            {FooterBar()}
        </div>
    )
}

export default Reu